<template>
    <div class="page">
        <el-row class="query-form p_b1">
            <el-col :span="16">
                <el-input size="small" clearable v-model="searchForm.serviceFacilitiesName" placeholder="请输入服务设施名称"
                          maxlength="50"
                          class="m_r1"
                          style="width: 20%" clearable></el-input>
                <el-button type="primary" @click="queryData(1)" size="small" icon="el-icon-search">查询</el-button>
                <el-button @click="resetSearch()" size="small" icon="el-icon-refresh-right">重置</el-button>
            </el-col>
            <el-col :span="8" class="text_right">
                <el-button type="primary" v-if="hasPermission('digitalProducts:add')"
                           @click="addEdit(1)" size="small">新增
                </el-button>
                <el-button type="primary" v-if="hasPermission('digitalProducts:delete')"
                           :disabled="dataListSelections.length <= 0" @click="del()" size="small">删除
                </el-button>
            </el-col>
        </el-row>
        <div class="bg-white">
            <el-table :data="list"
                      v-loading="loading"
                      @selection-change="selectionChangeHandle"
                      size="small"
                      height="calc(100vh - 280px)" class="table">
                <el-table-column :selectable="checkSelectable" type="selection" width="50" fixed></el-table-column>
                <el-table-column prop="serviceFacilitiesImage" label="图片" show-overflow-tooltip>
                    <template slot-scope="scope">
                        <el-image style="width: 100px; height: 100px" :src="scope.row.serviceFacilitiesImage"
                                  :fit="'fit'"></el-image>
                    </template>
                </el-table-column>
                <el-table-column prop="state" label="启用状态" show-overflow-tooltip>
                    <template slot-scope="scope">
                        <el-switch v-model="scope.row.state"
                                   @change="statusChange(scope.row)"
                                   :active-value="'0'"
                                   :inactive-value="'1'"
                        ></el-switch>
                    </template>
                </el-table-column>
                <el-table-column prop="serviceFacilitiesName" label="名称" show-overflow-tooltip></el-table-column>
                <el-table-column prop="serviceFacilitiesBriefIntroduction" label="简介"
                                 show-overflow-tooltip></el-table-column>
                <el-table-column prop="createUser" label="添加人" show-overflow-tooltip></el-table-column>
                <el-table-column prop="createTime" label="添加时间" show-overflow-tooltip>
                    <template slot-scope="scope">
                        {{ scope.row.createTime | formatDate}}
                    </template>
                </el-table-column>
                <el-table-column prop="updateTime" label="最后修改时间" width="120" show-overflow-tooltip>
                    <template slot-scope="scope">
                        {{ scope.row.updateTime | formatDate}}
                    </template>
                </el-table-column>
                </el-table-column>
                <el-table-column fixed="right" width="150" label="操作">
                    <template slot-scope="scope">
                        <!--                        <el-button v-if="hasPermission('questionnaire:view')" type="text" size="mini"-->
                        <!--                                   @click="view(scope.row.id)">查看-->
                        <!--                        </el-button>-->
                        <el-button v-if="hasPermission('digitalProducts:edit')" type="text" size="mini"
                                   @click="addEdit( 2,scope.row.id)">编辑
                        </el-button>
                        <el-button v-if="hasPermission('digitalProducts:delete')" :disabled="scope.row.state == '0'"
                                   type="text" size="mini"
                                   @click="del([scope.row.id])">删除
                        </el-button>
                    </template>
                </el-table-column>
            </el-table>
            <el-pagination
                    @size-change="sizeChangeHandle"
                    @current-change="currentChangeHandle"
                    :current-page="searchForm.current"
                    :page-sizes="[10, 20, 50, 100]"
                    :page-size="searchForm.size"
                    :total="total"
                    background
                    layout="total, sizes, prev, pager, next, jumper">
            </el-pagination>
        </div>

        <!--        新增，编辑-->
        <el-dialog
                :title="discriminate == 1 ? '新增服务设施':'编辑服务设施'"
                :visible.sync="dialogVisible"
                width="40%"
                :before-close="emptyNotification">
            <el-form size="small" :model="addruleForm" :rules="rules" ref="ruleForm" label-width="100px"
                     class="demo-ruleForm">
                <el-form-item label="名称" prop="serviceFacilitiesName">
                    <el-input style="width: 80%" placeholder="请输入地图名称（限20字）" maxlength="20"
                              v-model="addruleForm.serviceFacilitiesName"></el-input>
                </el-form-item>
                <el-form-item label="简介" prop="serviceFacilitiesBriefIntroduction">
                    <el-input
                            maxlength="200"
                            style="width: 80%"
                            type="textarea"
                            :rows="4"
                            placeholder="请输入简介（限200字）"
                            v-model="addruleForm.serviceFacilitiesBriefIntroduction">
                    </el-input>
                </el-form-item>
                <el-form-item label="图片" prop="serviceFacilitiesImage"
                              :rules="[{ required: true, message: '请上传图片', trigger: 'change' }]">
                    <ImgUpload :imgAskObj="imgUploadRequire" @getImgList="getImgList"></ImgUpload>
                </el-form-item>
                <el-form-item label="状态" prop="state">
                    <el-switch v-model="addruleForm.state"
                               :active-value="'0'"
                               :inactive-value="'1'"
                    ></el-switch>
                </el-form-item>
                <el-form-item v-if="discriminate == 2" label="添加人" prop="createUser">
                    <div>{{addruleForm.createUser}}</div>
                </el-form-item>
                <el-form-item v-if="discriminate == 2" label="添加时间" prop="createUser">
                    <div>{{addruleForm.createTime}}</div>
                </el-form-item>
                <el-form-item v-if="discriminate == 2" label="最后修改时间" prop="createUser">
                    <div>{{addruleForm.updateTime}}</div>
                </el-form-item>
                <div style="text-align: center">
                    <el-button v-if="hasPermission('digitalProducts:submit')" :loading="tijiao" @click="submit"
                               size="small" type="primary">提交
                    </el-button>
                    <el-button @click="emptyNotification" size="small">取消</el-button>
                </div>
            </el-form>
        </el-dialog>
    </div>
</template>

<script>
    import ImgUpload from "@/components/imgUpload/imgUpload";

    export default {
        components: {ImgUpload},
        data() {
            return {
                searchForm: {
                    serviceFacilitiesName: '', //产品名称
                    current: 1,
                    size: 10,
                },
                list: [],
                total: 0,
                loading: false,
                dataListSelections: [],
                addruleForm: {
                    serviceFacilitiesName: '',
                    serviceFacilitiesBriefIntroduction: '',
                    serviceFacilitiesImage: '',
                    state: "'0'",
                },
                rules: {
                    serviceFacilitiesImage: [{required: true, message: '请上传一张图片', trigger: 'change'},],
                    serviceFacilitiesName: [{required: true, message: '请输入服务设施名称', trigger: 'blur'},],
                },
                dialogVisible: false,
                imgUploadRequire: {
                    imgCount: 1, // 上传数量 0表示不限制数量
                    imgList: [], // 已上传的文件
                    uploadPath: "course",
                    imgSize: 0, // 图片尺寸
                    listType: 'picture-card', // 图片缩略图类型
                    imgNum: 0, // 上传文件兆数 0表示不限兆数
                },
                tijiao: false,
                discriminate: '1',//1新增 ，2编辑
            }
        },
        mounted() {
            this.queryData(1)
        },
        methods: {
            // 获取数据
            queryData(type) {
                if (type == 1) {
                    this.searchForm.current = 1
                }
                this.loading = true
                this.$axios(this.api.installation.list, this.searchForm, 'get').then((res) => {
                    if (res.status) {
                        this.list = res.data.records
                        if (this.list.length == 0 && this.searchForm.current > 1) {
                            this.searchForm.current--
                            this.queryData()
                        }
                        this.total = parseInt(res.data.total)
                    } else {
                        this.$message.error('查询失败');
                    }
                    this.loading = false
                })
            },

            checkSelectable(row) {
                return row.state == '1'
            },

            // 重置
            resetSearch() {
                this.searchForm = {
                    serviceFacilitiesName: '',
                    current: 1,
                    size: 10,
                }
                this.queryData(1)
            },
            // 状态修改
            statusChange(row) {
                this.$axios(this.api.installation.updateById, {
                    id: row.id,
                    state: row.state
                }, 'put').then((res) => {
                    if (res.status) {
                        this.$message.success('操作成功')
                        this.queryData();
                    } else {
                        this.$message.error(res.msg)
                        this.queryData();
                    }
                })
            },

            // 删除
            del(id) {
                let ids = id || this.dataListSelections.map(item => {
                    return item.id
                })
                this.$confirm(`确定删除这条数据吗?`, "提示", {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消",
                    type: "warning",
                }).then(() => {
                    this.$axios(this.api.installation.batchRemove, ids, 'post').then((res) => {
                        if (res.status) {
                            this.$message.success(res.msg)
                            this.queryData();
                        } else {
                            this.$message.error(res.msg)
                        }
                    })
                });
            },

            // 新增、编辑 method：1新增，2编辑
            addEdit(method, id) {
                this.discriminate = method;
                if (method == 2) {
                    this.$axios(this.api.installation.getById + id, {}, 'get').then(res => {
                        this.addruleForm = res.data;
                        this.imgUploadRequire.imgList.push({
                            url: res.data.serviceFacilitiesImage
                        })
                    })
                }
                this.dialogVisible = true;
            },

            // 每页数
            sizeChangeHandle(val) {
                this.searchForm.size = val
                this.searchForm.current = 1
                this.queryData()
            },
            // 当前页
            currentChangeHandle(val) {
                this.searchForm.current = val
                this.queryData()
            },
            // 多选
            selectionChangeHandle(val) {
                this.dataListSelections = val
            },

            // 获取上传图片数据
            getImgList(data) {
                if (data.length > 0) {
                    this.addruleForm.serviceFacilitiesImage = data[0].imgUrl
                } else {
                    this.addruleForm.serviceFacilitiesImage = ''
                }
            },

            //新增提交
            submit() {
                this.tijiao = true
                this.$refs.ruleForm.validate((valid) => {
                    if (valid) {
                        if (this.addruleForm.serviceFacilitiesName.trim() == '') {
                            this.$message.error('请输入服务设施名称')
                        }
                        if (this.addruleForm.id) {
                            this.$axios(this.api.installation.updateById, {
                                id: this.addruleForm.id,
                                state: this.addruleForm.state,
                                serviceFacilitiesName: this.addruleForm.serviceFacilitiesName,
                                serviceFacilitiesImage: this.addruleForm.serviceFacilitiesImage,
                                serviceFacilitiesBriefIntroduction: this.addruleForm.serviceFacilitiesBriefIntroduction,
                            }, 'put').then(res => {
                                if (res.status) {
                                    this.$message.success('修改成功')
                                    this.queryData()
                                    this.tijiao = false
                                    this.emptyNotification()
                                }
                            })
                        } else {
                            this.$axios(this.api.installation.save, this.addruleForm, 'post').then(res => {
                                if (res.status) {
                                    this.$message.success('保存成功')
                                    this.queryData()
                                    this.tijiao = false
                                    this.emptyNotification()
                                }
                            })
                        }
                    } else {
                        this.tijiao = false
                        console.log('error submit!!');
                        return false;
                    }
                });
            },

            //清空弹窗
            emptyNotification() {
                this.addruleForm = {
                    serviceFacilitiesName: '',
                    serviceFacilitiesBriefIntroduction: '',
                    serviceFacilitiesImage: '',
                    state: "'0'",
                }
                this.imgUploadRequire.imgList = [];
                this.dialogVisible = false;
            },
        }
    }
</script>

<style>
    .el-tooltip__popper {
        max-width: 60% !important;
    }
</style>
